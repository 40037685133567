// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";  // Make sure to import getAuth
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';  

// TODO: Add SDKs for Firebase products that you want to use


// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkl_RFF8Ed3NmB0wQiHajaN64tCEHGJog",
  authDomain: "radscriber.firebaseapp.com",
  projectId: "radscriber",
  storageBucket: "radscriber.appspot.com",
  messagingSenderId: "1048547803347",
  appId: "1:1048547803347:web:d9a9d6cd46fee060e05426",
  measurementId: "G-FHXX79LF50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);  // Initialize Firestore

export { auth, db };  // Make sure to export db