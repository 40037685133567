import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';

const TemplateDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();  // Used for navigation after delete
    const [user] = useAuthState(auth);
    const [template, setTemplate] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [templateCategory, setTemplateCategory] = useState('');
    const [templateContent, setTemplateContent] = useState('');

    useEffect(() => {
        if (user) {
            const fetchTemplate = async () => {
                const templateRef = doc(db, 'users', user.uid, 'templates', id);
                const docSnap = await getDoc(templateRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setTemplate(data);
                    setTemplateName(data.templateName);
                    setTemplateCategory(data.templateCategory);
                    setTemplateContent(data.templateContent);
                } else {
                    console.log('No such document!');
                }
            };

            fetchTemplate();
        }
    }, [user, id]);

    const updateTemplate = async () => {
        const templateRef = doc(db, 'users', user.uid, 'templates', id);
        await updateDoc(templateRef, {
            templateName,
            templateCategory,
            templateContent
        });
        alert('Template updated!');
    };

    const deleteTemplate = async () => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            const templateRef = doc(db, 'users', user.uid, 'templates', id);
            await deleteDoc(templateRef);
            alert('Template deleted!');
            navigate('/account/templates');  // Redirect to the templates list
        }
    };

    if (!template) return <div>Loading...</div>;

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Edit Template</h2>

            <label style={styles.label}>Template Name</label>
            <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                style={styles.input}
            />
            <label style={styles.label}>Template Comment/Category</label>
            <input
                type="text"
                value={templateCategory}
                onChange={(e) => setTemplateCategory(e.target.value)}
                style={styles.input}
            />
            <label style={styles.label}>Template Content</label>
            <textarea
                value={templateContent}
                onChange={(e) => setTemplateContent(e.target.value)}
                style={styles.textarea}
            />
            <button onClick={updateTemplate} style={styles.button}>Update</button>
            <button onClick={deleteTemplate} style={{...styles.button, backgroundColor: '#dc3545'}}>Delete Template</button>
        </div>
    );
};

// Styles remain the same as previously defined
const styles = {
    container: {
        margin: '0 auto',
        maxWidth: '600px',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#292929',
        fontFamily: 'Arial, sans-serif',
        color: '#EEE',
    },
    header: {
        marginBottom: '20px',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        height: '35px',
        padding: '6px 10px',
        fontSize: '16px',
        border: '1px solid #444',
        borderRadius: '4px',
        backgroundColor: '#444',
        color: '#EEE',
        marginBottom: '15px',
        marginTop: "10px"
        
    },
    textarea: {
        width: '100%',
        minHeight: '300px',
        padding: '6px 10px',
        fontSize: '16px',
        border: '1px solid #444',
        borderRadius: '4px',
        backgroundColor: '#444',
        color: '#EEE',
        marginTop: "10px"
       
    },
    button: {
        display: 'block',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        color: '#EEE',
        backgroundColor: '#4FC3F7',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
        transition: 'background-color 0.3s ease',
    }
};


export default TemplateDetail;
