import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';

const TemplateList = () => {
    const [user] = useAuthState(auth);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (user) {
            const templatesRef = collection(db, 'users', user.uid, 'templates');
            const q = query(templatesRef);
            getDocs(q).then(snapshot => {
                setTemplates(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            });
        }
    }, [user]);

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Your Templates</h1>
            {templates.length > 0 ? (
                <ul style={styles.list}>
                    {templates.map(template => (
                        <li key={template.id} style={styles.listItem}>
                            <Link to={`/account/template/${template.id}`} style={styles.link}>
                                {template.templateName}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>........</p>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#1e1e1e', // Dark background color
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        margin: '20px',
        minHeight: '300px'
    },
    header: {
        color: '#fff', // White text color
        textAlign: 'center',
        marginBottom: '20px'
    },
    list: {
        listStyleType: 'none',
        padding: 0
    },
    listItem: {
        padding: '10px',
        borderBottom: '1px solid #333', // Darker border color
        backgroundColor: '#2b2b2b', // Slightly lighter background color
    },
    link: {
        textDecoration: 'none',
        color: '#4fc3f7', // Light blue for links
        fontSize: '18px',
        fontFamily: 'Arial, sans-serif' // Modern font family
    }
};


export default TemplateList;
