import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs, query } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';
import './LanguageModelComponent.css';

const LanguageModelComponent = () => {
  const [user] = useAuthState(auth);
  const [inputText, setInputText] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiChoice, setApiChoice] = useState('groq');  // 'groq' or 'openai' or 'vllm'

  useEffect(() => {
    if (user) {
      const templatesRef = collection(db, 'users', user.uid, 'templates');
      const q = query(templatesRef);
      getDocs(q).then(snapshot => {
        const loadedTemplates = snapshot.docs.map(doc => ({
          id: doc.id,
          templateName: doc.data().templateName,
          templateContent: doc.data().templateContent
        }));
        setTemplates(loadedTemplates);
        if (loadedTemplates.length > 0) {
          setSelectedTemplate(loadedTemplates[0]);
          setResponse(loadedTemplates[0].templateContent);
        }
      });
    }
  }, [user]);

  const handleSubmit = async () => {
    if (selectedTemplate) {
      setIsLoading(true);
      let responseData;
      try {
        if (apiChoice === 'groq') {
          responseData = await getGroqChatCompletion(inputText, selectedTemplate.templateContent);
        }
        else if (apiChoice === 'vllm') {
            responseData = await getVllmchatcompletion(inputText,selectedTemplate.templateContent);
         } 
        else if (apiChoice === 'openai') {
          responseData = await getOpenAiChatCompletion(inputText, selectedTemplate.templateContent);
        }
        setResponse(responseData);
      } catch (error) {
        console.error('Error when calling API:', error);
        setResponse("Error processing your request.");
      }
      setIsLoading(false);
    }
  };

  const handleTemplateChange = (e) => {
    const newSelectedTemplate = templates.find(t => t.templateName === e.target.value);
    setSelectedTemplate(newSelectedTemplate);
    setResponse(newSelectedTemplate.templateContent);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(response).then(() => {
      alert('Text copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className="container">
      <select
        className="api-select"
        value={apiChoice}
        onChange={(e) => setApiChoice(e.target.value)}
      >
        <option value="groq">Groq API (Llama3 70b) </option>
        <option value="openai">OpenAI API (GPT-4o)</option>
        <option value="vllm">Local VLLM</option>
      </select>
      <textarea
        className="input-text"
        placeholder="Enter your text here"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <select
        className="template-select"
        value={selectedTemplate ? selectedTemplate.templateName : ''}
        onChange={handleTemplateChange}
      >
        {templates.map((template) => (
          <option key={template.id} value={template.templateName}>{template.templateName}</option>
        ))}
      </select>
      <button className="submit-button" onClick={handleSubmit}>Submit</button>
      {isLoading ? <div className="loading-indicator">Working...</div> : null}
      <textarea
        className="output-text"
        readOnly
        value={response}
      />
      <button className="copy-button" onClick={handleCopyToClipboard}>Copy to Clipboard</button>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          background-color: #292929;
          color: #EEE;
          font-family: Arial, sans-serif;
        }
        .input-text, .output-text, .template-select, .submit-button, .copy-button, .api-select {
          width: 90%;
          margin: 10px;
          padding: 10px;
          border: 1px solid #444;
          border-radius: 5px;
          background-color: #444;
          color: #EEE;
        }
        .input-text{
          height: 150px;
          resize: vertical;
        }
         .output-text {
            height: 600px;
            resize: vertical;
          }
        .submit-button, .copy-button {
          background-color: #4FC3F7;
          color: white;
          cursor: pointer;
          border: none;
          transition: background-color 0.3s ease;
        }
        .loading-indicator {
          color: #4FC3F7;
          font-size: 16px;
          margin-top: 10px;
        }
      `}</style>

    </div>
  );
};

export default LanguageModelComponent;

async function getGroqChatCompletion(inputText, templateContent) {
    const apiKey = "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"
    const url = "https://api.groq.com/openai/v1/chat/completions";
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: [{
            role: "user",
            content: `You are an radiologist. Organize free text findings: ${inputText} into the appropriate headings for a radiology report: ${templateContent}. Return ALL headings IN SAME ORDER back to user. Correct basic grammar and spelling errors. Capitalize the first word from each sentence. If no heading matches for a sentence, list Unremarkable under that heading. Do not add any content. Do not repeat sentences.  Do not add any text at the beginning, start with the first heading and end with last heading. Just return the headings and the sentences ONLY, that's it. For example Do not return Here is the organized radiology report:`
          }],
          model: "llama3-70b-8192"
        })
      });
      const data = await response.json();
      return data.choices[0]?.message?.content || "No response received.";
    } catch (error) {
      console.error("Error when calling Groq API:", error);
      return "Error processing your request.";
    }
  }


  async function getOpenAiChatCompletion(inputText, templateContent) {
    const apiKey = "sk-proj-BQKrpdd5iiG34fYzquq2T3BlbkFJnSlFenvs70o30XignuSS" // Ensure this is set correctly in your environment variables
    const url = "https://api.openai.com/v1/chat/completions";
  
    // Prepare the request payload
    const payload = {
      model: "gpt-4o",
      messages: [

        {
          role: "user",
          content: `You are a radiologist. Organize free text findings: ${inputText} into the appropriate headings for a radiology report: ${templateContent}. Return ALL headings IN SAME ORDER back to user. Correct basic grammar and spelling errors. Capitalize the first word from each sentence. If no heading matches for a sentence, list Unremarkable under that heading. Do not add any content. Do not repeat sentences.  Do not add any text at the beginning, start with the first heading and end with last heading. Just return the headings and the sentences ONLY, that's it.`

        }
      ]
    };
  
    // Log the request payload and headers to the console
    console.log("Sending request to OpenAI:", payload);
    console.log("API Key being used:", apiKey);
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      // Optionally, log the API response for further debugging
      console.log("Response from OpenAI:", data);
  
      return data.choices[0]?.message?.content || "No response received.";
    } catch (error) {
      console.error("Error when calling OpenAI API:", error);
      return "Error processing your request.";
    }
  }
  


  async function getVllmchatcompletion(inputText, templateContent) {
    const url = "https://tangy-mammals-play.loca.lt/v1/completions";

    // Prepare the request payload
    const payload = {
        model: "meta-llama/Meta-Llama-3-8B-Instruct",
        prompt: `Organize free text findings: ${inputText} into the appropriate headings for a radiology report: ${templateContent}. Return ALL headings IN SAME ORDER back to user. Correct basic grammar and spelling errors. Capitalize the first word from each sentence. If no heading matches for a sentence, list Unremarkable under that heading. Do not add any content. Do not repeat sentences.  Do not add any text at the beginning, start with the first heading and end with last heading. Just return the headings and the sentences ONLY, that's it.`,
        max_tokens: 700,
        temperature: 0
    };

    // Log the request payload and headers to the console
    console.log("Sending request to vLLM:", payload);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        const data = await response.json();

        // Optionally, log the API response for further debugging
        console.log("Response from VLLM:", data);

        return data.choices[0]?.text || "No response received.";
    } catch (error) {
        console.error("Error when calling VLLM API:", error);
        return "Error processing your request.";
    }
}

  