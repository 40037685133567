// LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase-config';
import { signOut } from 'firebase/auth';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            console.log("Logged out successfully!");
            navigate('/');  // Redirects to the login page or welcome page
        }).catch((error) => {
            console.error("Logout error:", error);
        });
    };

    return (
        <button onClick={handleLogout} style={{
            padding: '8px 16px', backgroundColor: '#dc3545', color: 'white',
            border: 'none', borderRadius: '4px', cursor: 'pointer'
        }}>
            Logout
        </button>
    );
};

export default LogoutButton;
