import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import TemplateList from './TemplateList';
import TemplateEditor from './TemplateEditor';
import TemplateDetail from './TemplateDetail';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';
import { signOut } from 'firebase/auth';
import LogoutButton from './LogoutButton'; 
import LanguageModelComponent from './LanguageModelComponent';


function App() {
  const [user] = useAuthState(auth);




  return (
    <Router>
      {user ? (
        <div style={styles.container}>
          <div style={styles.sidebar}>
            <h3>Welcome, {user.email}</h3>
          
            <ul style={{ listStyle: 'none', padding: 0 }}>
    <li><Link to="/account/templates" style={{ color: '#00FFFF' }}>Manage Templates</Link></li>
    <li><Link to="/account/create" style={{ color: '#00FFFF' }}>Create New Template</Link></li>
    <li><Link to="/account/test" style={{ color: '#00FFFF' }}>Test API</Link></li>
    



              <li> </li>
              <p></p>
              <li><LogoutButton /> </li> 
            
            </ul>
          </div>
          <div style={styles.templatesColumn}>
            <Routes>
              <Route path="/account/templates" element={<TemplateList />} />
              <Route path="/account/create" element={<TemplateEditor />} />
              <Route path="/account/test" element={<LanguageModelComponent />} />
              <Route path="/account/template/:id" element={<TemplateDetail />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {/* Redirect all other routes to login if not authenticated */}
        </Routes>
      )}
    </Router>
  );
}

const styles = {
    container: {
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: '#292929',
      color: '#EEE',
      fontFamily: 'Arial, sans-serif',
    },
    sidebar: {
      width: '200px',
      backgroundColor: 'black',
      padding: '20px',
    },
    templatesColumn: {
      width: '700px',
      backgroundColor: '#333',
      padding: '50px',
      overflowY: 'auto',
    },
    detailsColumn: {
      flex: 1,
      padding: '20px',
    },
    sidebarLink: {
      color: '#BDBDBD', // Lighter shade of grey for better contrast
      textDecoration: 'none',
      marginBottom: '10px',
      display: 'block',
    },
    logoutButton: {
      padding: '8px 16px',
      backgroundColor: '#dc3545',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    }
  };
  

export default App;
