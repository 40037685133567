import React, { useState } from 'react';
import { db } from './firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';

const TemplateEditor = () => {
    const [user] = useAuthState(auth);
    const [templateName, setTemplateName] = useState('');
    const [templateCategory, setTemplateCategory] = useState('');
    const [templateContent, setTemplateContent] = useState('');

    const saveTemplate = async () => {
        if (user) {
            const templatesRef = collection(db, 'users', user.uid, 'templates');
            await addDoc(templatesRef, {
                templateName,
                templateCategory,
                templateContent
            });
            alert('Template saved!');
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Create New Template</h2>
            <div style={styles.formGroup}>
                <label style={styles.label}>Template Name</label>
                <input
                    type="text"
                    value={templateName}
                    onChange={e => setTemplateName(e.target.value)}
                    style={styles.input}
                />
            </div>
            <div style={styles.formGroup}>
                <label style={styles.label}>Template Category</label>
                <input
                    type="text"
                    value={templateCategory}
                    onChange={e => setTemplateCategory(e.target.value)}
                    style={styles.input}
                />
            </div>
            <div style={styles.formGroup}>
                <label style={styles.label}>Content</label>
                <textarea
                    value={templateContent}
                    onChange={e => setTemplateContent(e.target.value)}
                    style={styles.textarea}
                />
            </div>
            <button onClick={saveTemplate} style={styles.button}>Save</button>
        </div>
    );
};

const styles = {
    container: {
        margin: '0 auto',
        maxWidth: '600px',
        padding: '20px 40px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#292929',
        fontFamily: 'Arial, sans-serif',
        color: '#EEE',
    },
    header: {
        marginBottom: '20px',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        height: '35px',
        padding: '6px 10px',
        fontSize: '16px',
        border: '1px solid #444',
        borderRadius: '4px',
        backgroundColor: '#444',
        color: '#EEE',
        marginBottom: '10px', // Add margin to separate fields vertically
    },
    textarea: {
        width: '100%',
        minHeight: '100px',
        padding: '6px 10px',
        fontSize: '16px',
        border: '1px solid #444',
        borderRadius: '4px',
        backgroundColor: '#444',
        color: '#EEE',
        marginBottom: '10px', // Add margin to separate fields vertically
    },
    button: {
        display: 'block',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        color: '#EEE',
        backgroundColor: '#4FC3F7',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        marginTop: '10px', // Add margin to separate button from fields
    }
};




export default TemplateEditor;
